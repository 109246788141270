import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';
import * as data from '../../assets/static.json';

@Injectable({
  providedIn: 'root'
})

export class WebApiHttp {
  public globalurl: string = data.test_url;
  // public globalurl1: string = data.test_url;
  public ApiURLArray: any = {


    // User URL Start

    login: '/api/User/Login',
    Logout: '/api/User/Logout',
    createUser: '/api/User/CreateUser',
    updateUser: '/api/User/UpdateUser',
    CreateUserEmployee: '/api/User/CreateUserEmployee',
    UserEmployeeFromEmpcode: '/api/User/UserEmployeeFromEmpcode?emp_code=',
    userList:'/api/User/UserList',
    AllUser: '/api/User/AllUser?name=',
    roleProcess: '/api/Role/RoleProcess',
    signalRNotification: '/Notification',
    locationlist: '/api/User/LocationList',

    UpdatePassword:'/api/User/UpdatePassword',

    ResetPasswordOTPSend:'/api/User/ResetPasswordOTPSend',
    ResetPasswordOTPVerify:'/api/User/ResetPasswordOTPVerify',


    // User URL End


    // candidate start

    CandidateList: '/api/Candidate/CandidateList',
    CandidateCreateorUpdate: '/api/Candidate/CandidateCreateorUpdate',
    CandidateFullInfo: '/api/Candidate/CandidateFullInfo',
    SelectedCandidateList: '/api/Candidate/SelectedCandidateList',
    RejectedCandidateList: '/api/Candidate/RejectedCandidateList',
    CandidateIssueOfferLetter: '/api/Candidate/CandidateIssueOfferLetter',
    OfferLetterIssuedCandidateList: '/api/Candidate/OfferLetterIssuedCandidateList',
    EmployeeCreateFromCandidate:'/api/Candidate/EmployeeCreateFromCandidate?candidate_id=',
    OfferLetterReport: '/api/Candidate/OfferLetterReport?candidate_id=',
    CandidateIssueOfferLetterSendMail: '/api/Candidate/CandidateIssueOfferLetterSendMail?candidate_id=',
    // candidate end

    //Application Form
    get_employee_application_details: '/api/EmployeeApplicationForm/get_employee_application_details?can_id=',
    EmployeeApplicationFormCreate: '/api/EmployeeApplicationForm/EmployeeApplicationFormCreate',
    employee_personal_info_update: '/api/EmployeeApplicationForm/employee_personal_info_update',
    employee_emergency_details_create: '/api/EmployeeApplicationForm/employee_emergency_details_create',
    employee_emergency_contact_delete: '/api/EmployeeApplicationForm/employee_emergency_delete?id=',
    employee_family_details_create:'/api/EmployeeApplicationForm/employee_family_details_create',
    employee_family_delete:'/api/EmployeeApplicationForm/employee_family_delete?id=',
    employee_bank_details_create:'/api/EmployeeApplicationForm/employee_bank_details_create',
    employee_bank_delete:'/api/EmployeeApplicationForm/employee_bank_delete?id=',
    employee_education_details_create:'/api/EmployeeApplicationForm/employee_education_details_create',
    employee_education_delete:'/api/EmployeeApplicationForm/employee_education_delete?id=',
    employee_work_experience_details_create:'/api/EmployeeApplicationForm/employee_work_experience_details_create',
    employee_work_experience_delete:'/api/EmployeeApplicationForm/employee_work_experience_delete?id=',
    employee_upload_documets: '/api/EmployeeApplicationForm/EmployeeDocUpload',
    employee_application_form_submit : '/api/EmployeeApplicationForm/employee_application_form_change_status_submitted?can_id=',

    get_submitted_application_details: '/api/EmployeeApplicationForm/get_submitted_application_details',
    get_submitted_application_details_for_candidate: '/api/EmployeeApplicationForm/get_submitted_application_details_for_candidate?can_id=',

    approve_application_form: '/api/EmployeeApplicationForm/create_employee',

//Application Form

    //Employee Application Form start
    get_employee_application_details_emp_code: '/api/EmployeeApplicationForm/get_employee_application_details_emp_code?emp_code=',
    employee_upload_documets_emp_code: '/api/EmployeeApplicationForm/EmployeeDocUploadEmpCode',
    employee_application_form_shift_calender: '/api/EmployeeApplicationForm/employee_application_form_shift_calender',
    employee_application_form_card_id: '/api/EmployeeApplicationForm/employee_application_form_card_id',

    //Employee Application Form End



    // Skill start

    SkillList: '/api/Skills/SkillList?filter=',
    SkillCreate:'/api/Skills/SkillCreate',
    SkillUpdate:'/api/Skills/SkillUpdate',
    SkillDelete:'/api/Skills/SkillDelete',

    // Skill end

    // Employees start
    // EmployeesList: '/api/Employees/EmployeesList',
    EmployeesList: '/api/Employees/get_employee_details_list',
    get_employee_list: '/api/Employees/get_employee_list',
    DepartmentList: '/api/Employees/DepartmentList',
    DesignationList: '/api/Employees/DesignationList?department=',
    CreateEmployess: '/api/Employees/CreateEmployess',
    GetEmployeeID: '/api/Employees/GetEmployeeID',
    EmployeeFullInfo: '/api/Employees/EmployeeFullInfo?emp_id=',
    // Employees end


    // Interview start

    InterviewCreate: '/api/Interview/CreateInterview',
    InterviewList: '/api/Interview/InterviewList',
    InterviewResult: '/api/Interview/InterviewResult',
    InterviewTypeList: '/api/Interview/InterviewTypeList',
    InterviewTypeCreate: '/api/Interview/InterviewTypeCreate',
    InterviewTypeUpdate: '/api/Interview/InterviewTypeUpdate',
    InterviewTypeDelete: '/api/Interview/InterviewTypeDelete',
    GetInterviewSchedule: '/api/Interview/GetInterviewSchedule?email_id=',
    GetSubmittedInterviews: '/api/Interview/GetSubmittedInterviews?email_id=',
    UpdateInterviewByInterviewer: '/api/Interview/UpdateInterviewByInterviewer',
    RejectionReasonList: '/api/Interview/RejectionReasonList',
    RejectionReasonCreate: '/api/Interview/RejectionReasonCreate',
    RejectionReasonUpdate: '/api/Interview/RejectionReasonUpdate',
    RejectionReasonDelete: '/api/Interview/RejectionReasonDelete',
    InterviewHistory:'/api/Interview/InterviewHistory?can_id=',

    // Interview end

    //Company Profile
    CompanyProfileList:'/api/CompanyProfileMaster/CompanyProfileList',
    CompanyProfileCreate:'/api/CompanyProfileMaster/CompanyProfileCreate',
    CompanyProfileDelete:'/api/CompanyProfileMaster/CompanyProfileDelete',
    CompanyProfileUpdate:'/api/CompanyProfileMaster/CompanyProfileUpdate',

    //Company Profile end



    // Attendance start

    AttendanceList: '/api/Attendance/AttendanceList',
    Current: '/api/Attendance/Current',
    Start: '/api/Attendance/Start',
    End: '/api/Attendance/End',
    Delete: '/api/Attendance/Delete',

    // Attendance end

    // leave start

    LeaveList: '/api/Leave/LeaveList',
    AvailableLeave: '/api/Leave/AvailableLeave',
    ApplyLeave: '/api/Leave/ApplyLeaveNew',
    LeaveTypeCreate: '/api/Leave/LeaveTypeCreate',
    LeaveTypeDelete: '/api/Leave/LeaveTypeDelete?leave_code=',
    LeaveForApproval: '/api/Leave/LeaveForApproval?emp_code=',
    LeaveApproval: '/api/Leave/LeaveApprovalNew',

    AppliedLeaveList:'/api/Leave/leave_approval_days',
    // leave end

    PrintSalary: '/api/Salary/PrintSalary',

    // apprasial start

    ListofAppraisal: '/api/AppraisalSetup/ListofAppraisal',
    AppraisalSeupCreate: '/api/AppraisalSetup/AppraisalSeupCreate',
    AppraisalSeupInfo: '/api/AppraisalSetup/AppraisalSeupInfo',

    // apprasial end

    // goal start

    GoalList: '/api/AppraisalSetup/GoalList',
    GoalCreate: '/api/AppraisalSetup/GoalCreate',
    GoalEdit: '/api/AppraisalSetup/GoalEdit',
    GoalDelete: '/api/AppraisalSetup/GoalDelete',
    GoalAchived: '/api/AppraisalSetup/GoalAchived',
    // goal end

    // kra start

    KRAlist: '/api/AppraisalSetup/KRAlist',
    KRACreate: '/api/AppraisalSetup/KRACreate',
    KRADelete: '/api/AppraisalSetup/KRADelete',

    // kra end

    // self apprasial start

    SelfAssessmentInfo: '/api/AppraisalSetup/SelfAssessmentInfo',
    SelfAssessmentCreate: '/api/AppraisalSetup/SelfAssessmentCreate',
    SelfAssessmentRating: '/api/AppraisalSetup/SelfAssessmentRating',
    TemplateList: '/api/SelfAppraisal/TemplateList',
    CheckForSubmitData: '/api/SelfAppraisal/CheckForSubmitData',
    TemplateDelete: '/api/SelfAppraisal/TemplateDelete',
    SelfAssessmenttemplateCreate: '/api/SelfAppraisal/SelfAssessmenttemplateCreate',

    // self appraisal end

    // emp_KRA start

    EmployeeKRATag: '/api/KRA/EmployeeKRATag',
    EmployeeKRAList: '/api/KRA/EmployeeKRAList',
    Employee_KRA_Weightage_update: '/api/KRA/Employee_KRA_Weightage_update',
    Employee_KRA_delete: '/api/KRA/Employee_KRA_delete',

    // emp_KRA end


    // emp_Skill start

    EmployeeSkillsTag: '/api/Skills/EmployeeSkillsTag',
    EmployeeSkillsList: '/api/Skills/EmployeeSkillsList',
    EmployeeSkillsUpgrade: '/api/Skills/EmployeeSkillsUpgrade',
    Employee_Skills_delete: '/api/Skills/Employee_Skills_delete',

    // emp_Skill end

    // multi reportee start

    ReportingPeopleList: '/api/ReportingAppraisal/ReportingPeopleList',

    // multi reportee end

    // multi reportee start

    // GetTickets: '/api/Tickets/GetCustTickets',
    GetTickets: '/api/Tickets/GetTickets',
    GetDashboardTicketStatus: '/api/Tickets/GetDashboardTicketStatus',
    // UpdateTicket: '/api/Tickets/UpdateCustTicket',
    UpdateTicket: '/api/Tickets/UpdateTicket',
    // GetTicketRemarks: '/api/Tickets/GetCustTicketRemarks',
    GetTicketRemarks: '/api/Tickets/GetTicketRemarks',

    // InsertTicketRemarks: '/api/Tickets/InsertCustTicketRemarks',
    InsertTicketRemarks: '/api/Tickets/InsertTicketRemarks',
    Reassign: '/api/Tickets/Reassign',
    WorkTypeList: '/api/Tickets/WorkTypeList',
    GetOpenTickets: '/api/Tickets/GetOpenTickets',
    TicketMarkReopen:'/api/Tickets/TicketMarkReopen',

    //Role Master
    // RoleMasterProcess: '/api/Tickets/RoleMasterProcess',
    // RolePermissionUpdate: '/api/Tickets/RolePermissionUpdate',

    RoleMasterProcess: '/api/Role/RoleProcess',
    RolePermissionDetail: '/api/Role/RolePermissionDetail/',
    RolePermissionUpdate: '/api/Role/RolePermissionUpdate',



    // multi reportee end

    // 2nd attendance start

    SecondAttendanceList: '/api/Attendance/SecondAttendanceList',
    ProjectList: '/api/Attendance/ProjectList',
    SecondAttendanceSubmit: '/api/Attendance/SecondAttendanceSubmit',
    AttendanceInfo: '/api/Attendance/AttendanceInfo',
    SecondAttendanceApprovalList: '/api/Attendance/SecondAttendanceApprovalList',
    SecondAttendanceApproval: '/api/Attendance/SecondAttendanceApproval',

    // 2nd attendance end


    // E_invoice start

    CustomerList: '/api/EInvoice/CustomerList',
    CustomerProjectSubcription: '/api/EInvoice/CustomerProjectSubcription',
    UploadEInvoice: '/api/EInvoice/CustomerProjectSubcription',
    E_invoice_Create:'/api/EInvoice/E_invoice_Create',

    // E_invoice end

    // Product Start
    ProductList: '/api/Product/ProductList',
    ProductCreate: '/api/Product/ProductCreate',
    PCustomerList: '/api/Product/CustomerList',
    ProductUpdate: '/api/Product/ProductUpdate',
    EmployeeList: '/api/Product/EmployeeList',


    CustomerProductList : '/api/Product/CustomerProductList',
    CustomerProductListByID : '/api/Product/CustomerProductListWithCustId?cust_id=',
    CustomerProductAdd: '/api/Product/CustomerProductCreate',
    CustomerProductUpdate: '/api/Product/customer_product_update',

    // Product End

    // Subscription
    SubscriptionList : '/api/Subscription/subscription_list',
    SubscriptionAdd : '/api/Subscription/subscription_create',
    subscription_line_add:'/api/Subscription/subscription_line_add',
    subscription_sub_id:'/api/Subscription/subscription_sub_id?sub_id=',
    delete_subscription_line:'/api/Subscription/delete_subscription_line?sub_id=',
    subscription_update:'/api/Subscription/subscription_update',

    // Subscription End

    // Licence Start
    LicenceRenewList : '/api/Licence/licence_renew_list',
    LicenceRenewCreate : '/api/Licence/licence_renew_create',
    LicenceList: '/api/Licence/licence_list',
    LicenceCreate: '/api/Licence/licence_create',
    LicenceView: '/api/Licence/licence_view?licence_no=',
    LicenceRenewUpdate : '/api/Licence/licence_renew_update',

    //Liscence End

    //Support Agreement Start
    SupportAgreementlist: '/api/SupportAggrement/support_aggrement_list',
    SupportAgreementCreate: '/api/SupportAggrement/support_aggrement_create',
    SupportAgreementUpdate: '/api/SupportAggrement/support_aggrement_update',

    //Support End

    //Notification start
    notification_list: '/api/SupportAggrement/notification_list',
    notification_create: '/api/SupportAggrement/notification_create',
    notification_update: '/api/SupportAggrement/notification_update',
    //Notification End

    //Timesheet start
    TimesheetCreate:'/api/Timesheet/TimesheetCreate',
    timesheet_details:'/api/Timesheet/timesheet_details?timesheet_no=',
    timesheet_details_date:'/api/Timesheet/timesheet_details_date?timesheet_no=',
    timesheet_header_details:'/api/Timesheet/timesheet_header_details?timesheet_no=',
    timesheet_list:'/api/Timesheet/timesheet_list?created_by=',
    TimeSheetLineDelete:'/api/Timesheet/TimeSheetLineDelete?timesheet_no=',
    TimeSheetAllLineDelete:'/api/Timesheet/TimeSheetAllLineDelete?timesheet_no=',
    TimesheetSubmitted:'/api/Timesheet/TimesheetSubmitted?timesheet_no=',
    Timesheetsetweekoff:'/api/Timesheet/Timesheetsetweekoff',
    GetAllTimesheet:'/api/Timesheet/GetAllTimesheet',

    //Timesheet end

    //Report
    get_timesheet_monthly_report:'/api/PortalReports/get_timesheet_monthly_report',
    get_employee_wise_leave_record:'/api/PortalReports/get_employee_wise_leave_record',
    get_customer_ticket_data:'/api/PortalReports/get_customer_ticket_data',
    get_customer_employee_cost:'/api/PortalReports/get_customer_employee_cost',
    get_employee_milestone_reports:'/api/PortalReports/get_employee_milestone_report',
    get_employee_ticket_reports:'/api/PortalReports/get_employee_ticket_report',
//Report End

    //Leave
    LeaveTypeList: '/api/Leave/leave_type',
    AssignLeaveType: '/api/Leave/LeaveAppliedCreate',
    AssignLeaveTypeList: '/api/Leave/LeaveAppliedView?leave_id=',
    DeleteAssignedLeaves: '/api/Leave/LeaveAppliedLineDelete?leave_id=',
    lineno:'&line_no=',
    SubmitAssignedLeaves: '/api/Leave/LeaveAppliedSubmited?leave_id=',

    //shift master
    getShiftMaster: '/api/ShiftMaster/getShiftMaster',
    shift_master_create: '/api/ShiftMaster/shift_master_create',
    shift_master_delete: '/api/ShiftMaster/shift_master_delete?shift_code=',
    //shift master

    //Calender master
    getCalenderMaster: '/api/CalenderMaster/getCalenderMaster',
    calender_master_create: '/api/CalenderMaster/calender_master_create',
    calender_master_delete: '/api/CalenderMaster/calender_master_delete?calender_code=',
    //Calender master

    //Pay Element master
    getPayElementMaster: '/api/PayElementMaster/getPayElementMaster',
    pay_element_master_create: '/api/PayElementMaster/pay_element_master_create',
    pay_element_master_delete: '/api/PayElementMaster/pay_element_master_delete?pay_code=',
    //Pay Element master

    //Pay Element master
    get_emp_pay_element_master: '/api/EmpPayElementMaster/get_emp_pay_element_master',
    emp_pay_element_master_create: '/api/EmpPayElementMaster/emp_pay_element_master_create',
    emp_pay_element_master_delete: '/api/EmpPayElementMaster/emp_pay_element_master_delete?emp_id=',
    //Pay Element master

    //Calender Date master
    getCalenderDateMaster: '/api/CalenderMaster/getCalenderDateMaster',
    calender_date_master_create: '/api/CalenderMaster/calender_date_master_create',
    calender_date_master_update: '/api/CalenderMaster/calender_date_master_update',
    calender_date_master_delete: '/api/CalenderMaster/calender_date_master_delete?calender_code=',
    //Calender Date master

    //Attendance
    getAttendanceList:'/api/Attendance/EmployeeMarkAttendance',
    getEmployeeList: '/api/Employees/get_employee_details_list',
    uploadAttendence: '/api/Attendance/EmployeeAttendanceUpload',
    EmployeeMarkAutoAttendance:'/api/Attendance/EmployeeMarkAutoAttendance',
    AttendanceUploader:'/api/Attendance/AttendanceUploader',

    //Project
    projectMaster: '/api/ProjectManagement/createProjectMst',
    projectTeam : '/api/ProjectManagement/createProjectTeam',
    projectList: '/api/ProjectManagement/getProjectList?status=',
    projectUpdate: '/api/ProjectManagement/updateProjectStatus',
    projectTeamRemove : '/api/ProjectManagement/deleteTeamMember',
    projectRenew: '/api/ProjectManagement/updateProjectMst',

    //Milestone
    getPersonResponsible: '/api/ProjectMilestone/getResponsiblePerson?project_id=',
    createProjectMilestone:'/api/ProjectMilestone/createProjectMilestone',
    getMilestone: '/api/ProjectMilestone/getProjectMilestone?project_id=',
    updateMilestone: '/api/ProjectMilestone/updateProjectMilestone',
    getMilestoneTicketCount:'/api/Tickets/GetTicketsStatusCount?milestone_no=',
    getProjectTeam:'/api/ProjectMilestone/getProjectTeam?project_id=',
    getProjectWithMilestone: '/api/ProjectManagement/getProjectList_by_employee_milestone',
    getMilestoneById: '/api/ProjectMilestone/getProjectMilestone_by_id',

    //employee ticket
    insertEmployeeTicket: '/api/Tickets/Insert_Ticket',
    //getTickets: '/api/Tickets/GetTickets',
    getMilestoneForTicket: '/api/ProjectMilestone/getProjectMilestone?project_id=',
    updateEmployeeTicket: '/api/Tickets/UpdateTicket',
    getTicketId: '/api/Tickets/GetTickets_by_id',
    empTicketReassign:'/api/Tickets/Employee_ticket_Reassign',

    //allticketget
    // GetAllTickets:'/api/Tickets/GetAllTickets',
    //Work status Master
    WorkStatusGet:'/api/Tickets/WorkStatusGet',
    WorkStatusCreate:'/api/Tickets/WorkStatusCreate',

    //Technical Tickets
    GetAllTickets:'/api/Tickets/GetAllTickets',
    EmployeeListGet:'/api/Product/EmployeeListGet',
    TicketTechnicalRequiredMark:'/api/Tickets/TicketTechnicalRequiredMark',
    TicketResolversInsert:'/api/Tickets/TicketResolversInsert',
    AllTicketGetCsvLink:'/api/Tickets/AllTicketGetCsvLink',
    TicketResolverMessageGet:'/api/Tickets/TicketResolverMessageGet',
    TicketResolverCommentGet:'/api/Tickets/TicketResolverCommentGet',
    TicketResolverCommentInsert:'/api/Tickets/TicketResolverCommentInsert',

  //Ticket Dashboard
    TicketsTechnicalAssignedCountGet:'/api/Tickets/TicketsTechnicalAssignedCountGet',
    GetTicketsAssignedTechincalWise:'/api/Tickets/GetTicketsAssignedTechincalWise',
    TicketWorkStatusUpdate:'/api/Tickets/TicketWorkStatusUpdate',
    GetTicketsByTicketId:'/api/Tickets/GetTicketsByTicketId',
    TicketsAssignedToCountGet:'/api/Tickets/TicketsAssignedToCountGet',
    GetTicketsAssignedToWise:'/api/Tickets/GetTicketsAssignedToWise',
    GetTicktesCountWorkStatusWise:'/api/Tickets/GetTicktesCountWorkStatusWise',
    GetAllticketsByAssignedTechincalWise:'/api/Tickets/GetAllticketsByAssignedTechincalWise',

  };

  constructor(private httpClient: HttpClient) {

  }

  get getHTTPHeader(): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  }

  getHTTPHeaderAuth(token: string): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
  }

  // post data to server
  async Post(path: string, jsondata: any): Promise<any> {
    try {
      path = this.globalurl + path;
      var headers = this.getHTTPHeader;
      return await new Promise<any>((resolve, reject) => {
        this.httpClient.post<any>(path, JSON.stringify(jsondata), headers).toPromise()
          .then(result => resolve(result), error => reject({
            condition: 'False',
            message: error.message
          })).catch(err => reject({condition: 'False', message: err.message}));
      });

    } catch (e) {
      return new Promise<any>((resolve) => {
        resolve({condition: 'False', message: e.message});
      });
    }
  }

  // get data to the server
  async Get(path: string): Promise<any> {
    try {
      path = this.globalurl + path;
      var headers = this.getHTTPHeader;
      return await new Promise<any>((resolve, reject) => {
        this.httpClient.get<any>(path, headers).toPromise()
          .then(result => resolve(result), error => reject({
            condition: 'False',
            message: error.message
          })).catch(err => reject({condition: 'False', message: err.message}));
      });
    } catch (e) {
      return new Promise<any>((resolve) => {
        resolve({condition: 'False', message: e.message});
      });
    }
  }

  // For formdata
  async PostFormData(path: string, formdata: any): Promise<any> {
    try {
      path = this.globalurl + path;
      var header = this.getHTTPHeader;
      return await new Promise<any>((resolve, reject) => {
        this.httpClient.post<any>(path, formdata).toPromise()
          .then(result => resolve(result), error => reject({
            condition: 'false',
            message: error.message
          })).catch(error => reject({
          condition: 'false',
          message: error.message
        }));
      });

    } catch (e) {
      return new Promise<any>((resolve) => {
        resolve({condition: 'false', message: e.message});
      });
    }
  }

  // post data to server and get two type of response
  Post_Data_GetFile(path: string, jsondata: any) {
    path = this.globalurl + path;
    const request = new HttpRequest('POST', path, jsondata, {
      responseType: 'blob',
      reportProgress: true,
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
    return this.httpClient.request(request);
  }

  Get_Data_With_DownloadStatus_GetFile(path: string) {
    path = this.globalurl + path;
    const request = new HttpRequest('GET', path, {
      responseType: 'blob',
      reportProgress: true,
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
    return this.httpClient.request(request);
  }

  blobToString(b) {
    var urldata, x;
    urldata = URL.createObjectURL(b);
    x = new XMLHttpRequest();
    x.open('GET', urldata, false); // although sync, you're not fetching over internet
    x.send();
    URL.revokeObjectURL(urldata);
    return x.responseText;
  }
}
